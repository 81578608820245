import React from "react";
import Header from "../Header";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Footer from "../Footer";

import { useTranslation } from "react-i18next";

const Prospektus = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header></Header>
      {/* ************************************************
    Banner
    ************************************************ */}
      <section id="investor_info">
        <Container>
          <Row>
            <Col sm={6}>
              <div className="content-panel">
                <h6>{t("investor_info_title")}</h6>
                <h2>{t("prospectus_title")}</h2>
              </div>
            </Col>
          </Row>
        </Container>
        <Image src="../images/investor-info-img.jpg" />
      </section>

      {/* ************************************************
    Body Content
    ************************************************ */}
      <section id="prospektus">
        <Container>
          <Row>
           
            <Col sm={3}>
              <div className="prospektus-card">
                <Image src="../images/prospectus-img.jpg" />
                <h4>{t("preliminary_prospectus")}</h4>
                <Button>
                  <i className="bi bi-download"></i> {t("download")}
                </Button>{" "}
              </div>
 <div className="prospektus-card">
                <Image src="../images/prospectus-img.jpg" />
                <h4>Testing Garin</h4>
                <Button>
                  <i className="bi bi-download"></i> {t("download")}
                </Button>{" "}
              </div>
            </Col>
            
            
            
            
          </Row>
        </Container>
      </section>

      <Footer></Footer>
    </>
  );
};

export default Prospektus;
